import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import PropTypes from 'prop-types';

import Layout from "../components/layout";
import Theme from "../styles/theme";
import SEO from "../components/seo";
import Contact from "../components/contact/contact";
import Card2 from "../components/card2";

interface HomePageProps {
  location: Location;
  pageContext: {
    images: any;
  };
}

export const OfficeLogo = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 100px 50px 50px 50px;
  opacity: 0.1;

  @media (max-width: ${Theme.breakpoints.sm}) {
  }
`;


export const OfficePolygon = styled.polygon`
  fill: #f04e1f;
`;

export const OfficePath = styled.path`
`;

export const OfficeSvg = styled.svg`
  height: 100%;
  width: 100%;
`;

const HomeOuterContainer = styled.div<{ background?: string }>`
  ${props => props.background && `
    background-color: ${props.background};
  `};

  @media (max-width: ${Theme.breakpoints.sm}) {
    top: -40px;

  }
`;

const HomeContainer = styled.div<{ background?: string }>`
  min-height: 200px;
  width: 100%;
  max-width: ${Theme.components.container.width};
  margin: auto;
  ${props => props.background && `
    background-color: ${props.background};
  `};
  padding: 25px;
  @media (max-width: ${Theme.breakpoints.xl}){
    padding: 10px;
  }
`;

const PostsContainer = styled.div`
  padding: 10px 0 30px 0;
  background-color: ${Theme.layout.secondaryColor};
  > div {
    max-width: ${Theme.components.container.width};
    margin: 0 auto;
  }
  h1 {
    color: ${Theme.layout.white};
  }
  @media (max-width: ${Theme.breakpoints.xl}) {
    h1 {
      padding-left: 30px;
    }
  }

`

const SectionTitle = styled.h1<{ light?: boolean }>`
  width: 100%;
  text-align: left;
  text-align: center;
  margin: 10px auto 0 auto;
  ${props => props.light === true ? `
    max-width: ${Theme.components.container.width};
    color: ${Theme.layout.white};
  ` : `
    color: ${Theme.layout.black};

  `}
  padding-left: 10px;
`;
const SectionSubtitle = styled.h2<{ light?: boolean }>`
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
  text-align: center;
  ${props => props.light === true ? `
    max-width: ${Theme.components.container.width};
    margin: 10px auto;
    color: ${Theme.layout.white};
  ` : `
    color: ${Theme.layout.black};
  `}
`;

const Subtitle = styled.h2<{ light?: boolean }>`
  width: 100%;
  text-align: left;
  padding-top: 0;
  padding-bottom: 10px;
  text-align: center;
  margin: 5px auto;
  ${props => props.light === true ? `
    max-width: ${Theme.components.container.width};
    color: ${Theme.layout.white};
  ` : `
    color: ${Theme.layout.black};

  `}
`;

const Body = styled.span`
  display: inline-block;
  width: 100%;
  opacity: 1;
  padding: 10px;
  text-align: justify;
  text-align-last: center;
`;


const SectionTitleSmall = styled.h3`
  // color: ${Theme.layout.white};
`;

const SectionText = styled.p`
  // color: ${Theme.layout.white};
  padding: 0 25px ;
  text-align: justify;

`;

export const FeaturedImage = styled(GatsbyImage)`
  background-position: center;
  background-size: cover;
  max-width: 100%;
  border-top-left-radius: 3px;
  width: 50%;
  float: left;
  margin-right: 30px;
  height: 320px;
  border-bottom-left-radius: 3px;

  @media (max-width: ${Theme.breakpoints.sm}) {
    width: 100%;
    float: none;
    height: 190px;
  }

`;

const ServiceBlockContainer = styled.div`
padding: 25px;
@media (max-width: ${Theme.breakpoints.xl}){
  padding: 10px;
}

background-color: ${Theme.layout.primaryColor};
`

const ServiceBlockWrapper = styled.div`
  flex: 1 0 30%; /* explanation below */
  max-width: ${Theme.components.container.width};
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 30px 10px 30px;
    gap: 10px;
  }
`


const HomePage: FunctionComponent<HomePageProps> = ({ pageContext, location }) => {

  // const query = useStaticQuery(graphql`{
  //   allFile(filter: {name: {}, relativePath: {regex: "/cards/"}}) {
  //     edges {
  //       node {
  //         relativePath
  //         name
  //         id
  //         childImageSharp {
  //           gatsbyImageData(height: 600, layout: FULL_WIDTH)
  //         }
  //       }
  //     }
  //   }
  // }`);

  return (
    <Layout>
      <SEO location={location} type={`WebSite`} />
      <HomeOuterContainer>
        <HomeContainer>
          <SectionTitle>Shepherd Contracting West Sussex</SectionTitle>
          <Subtitle>Estate and Property Maintenance, Kitchen and Bathroom Fitting</Subtitle>
          <Body>Shepherd Contracting is a maintenance business which offers the unique ability to cover all aspects of work from one point of contact. From kitchen and bathroom installations &amp; refurbishments to simple planned maintenance ‐ we've got it covered!</Body>
          <Body>Over our many years of working in the building industry we've realised that quality of work and being able to react at short notice is important to our clients so that's what we focus on. </Body>
          <Body>Coming from a background in private household maintenance we've worked on a number of period properties and have a developed close working relationship with excellent carpenters, joiners, plumbers, heating engineers, electricians, decorators, architects, groundworkers, roofers, flooring specialists, tree surgeons, garden designers - the list is endless! This really does mean that we can cover any aspect that you need.</Body>
          <Body>Please take a look at some of the images of our work and if you have any questions please don't hesitate to contact us.</Body>
        </HomeContainer>
      </HomeOuterContainer>
      <ServiceBlockContainer>
        <SectionSubtitle light={true}>Areas of expertise</SectionSubtitle>
        <ServiceBlockWrapper>
          {pageContext.images && pageContext.images.map(edge =>
            <Card2 key={edge.id} img={edge.childImageSharp.middle} title={edge.name}></Card2>
          )}
        </ServiceBlockWrapper>
      </ServiceBlockContainer>
      <Contact></Contact>
    </Layout>
  );
};


HomeContainer.propTypes = {
  background: PropTypes.string
};

export default HomePage;
