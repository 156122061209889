import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"
import Theme from '../styles/theme';
import { navigate } from 'gatsby';

const Card2Container = styled.div`
  background: #fff;
  flex: 1 0 30%;
  border-radius: 0.2em;
  overflow: hidden;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  transition: all ease 200ms;
  cursor: pointer;

  &:hover{
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  }

  img {
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: ${Theme.breakpoints.lg}) {
    flex: 1 0 48%;

  }

  @media (max-width: ${Theme.breakpoints.sm}) {
    margin: 20px auto;
    width: 100%;
    transition: none;
    &:hover{
      -webkit-transform: none;
      -moz-transform: none;
      -ms-transform: none;
      transform: none;
      box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3), 0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
    }
  }
`

const Card2Body = styled.div`

  h2 {
    text-align: center;
  }
`

const Card2 = ({ img, title }) => {
  return (
    <Card2Container onClick={() => navigate(`/${title.replace(/\s+/g, '-').toLowerCase()}`)}>
      <GatsbyImage image={img} alt={title} style={{ height: '250px' }} />
      <Card2Body>
        <h2>{title}</h2>
      </Card2Body>
    </Card2Container>
  )
}

export default Card2